//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginCard from '@/components/common/login-card'
import RegisterCard from '@/components/common/register-card'
import ChangePasswordCard from '@/components/common/change-password-card'
import {availableModules} from '@/enums/availableModules'
import {mapActions, mapGetters, mapState} from 'vuex'
import {adminCodes} from '~/enums/adminSettings'

export default {
  components: {
    RegisterCard,
    LoginCard,
    ChangePasswordCard
  },
  data() {
    return {
      visibleCard: 'login',
      userId: {
        type: Number
      },
      userName: '',
      showPoweredByLink: true,
    }
  },
  head() {
    return {
      title: this.$t('login'),
    }
  },
  computed: {
    IsLoggedIn() {
      return this.$auth.loggedIn
    },
    ...mapState('common/cache', ['loadSettings']),
    ...mapGetters('backoffice/settings', ['adminSettingByCode']),
    ContactPhoneNumber() {
      return this.adminSettingByCode(adminCodes.ContactPhoneNumber).value
    },
    ContactEmailAddress() {
      return this.adminSettingByCode(adminCodes.ContactEmailAddress).value
    },
  },
  async mounted() {
    if (this.IsLoggedIn) {
      await this.proceedToSelectedModule()
    }
    const showPoweredByLinkAdminSetting = await (this.fetchAdminSettingByCode(adminCodes.show_powered_by_piro_link_on_the_backoffice_login))
    this.showPoweredByLink = showPoweredByLinkAdminSetting?.value === 'true'
  },
  methods: {
    ...mapActions('common/cache', ['loadingCache']),
    ...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    async loggedIn() {
      await this.loadingCache()
      await this.proceedToSelectedModule()
    },
    async proceedToSelectedModule() {
      const allAvailableModules = this.$store.getters[
        'common/userProfile/getAvailableModules'
      ].call()
      const userInfo = this.$store.getters[
        'common/userProfile/getUserDetails'
      ].call()
      const currentlySelected = allAvailableModules.filter(
        (module) => module.value === userInfo.startupModuleID
      )

      if (currentlySelected[0]) {
        switch (currentlySelected[0].text) {
          case availableModules.Dashboard:
            await this.$router.push('/backoffice/dashboard')
            break
          case availableModules.Configuration:
            await this.$router.push('/backoffice/configuration')
            break
          case availableModules.Catalog:
            await this.$router.push('/backoffice/catalog')
            break
          case availableModules.CustomerCredits:
            await this.$router.push('/backoffice/financial/customer-credits')
            break
          case availableModules.Invoices:
            await this.$router.push('/backoffice/financial/invoices')
            break
          case availableModules.Payments:
            await this.$router.push('/backoffice/financial/payments')
            break
          case availableModules.CustomOrder:
            await this.$router.push('/backoffice/order-creation/custom-order')
            break
          case availableModules.Estimate:
            await this.$router.push('/backoffice/order-creation/estimate')
            break
          case availableModules.JobOrders:
            await this.$router.push('/backoffice/status-tracking/job-orders')
            break
          case availableModules.Quotes:
            await this.$router.push('/backoffice/status-tracking/quotes')
            break
          case availableModules.SalesOrders:
            await this.$router.push('/backoffice/status-tracking/sales-orders')
            break
        }
      } else {
        const adminSettingInitialModule = this.adminSettingByCode(
          adminCodes.REDIRECT_AFTER_BACKEND_LOGIN
        )

        if (adminSettingInitialModule && adminSettingInitialModule.value)
          await this.$router.push(adminSettingInitialModule.value)
        else await this.$router.push('/backoffice/dashboard')
      }
    },
    mustChangePassword(id, userName) {
      this.visibleCard = 'changePassword'
      this.userId = id
      this.userName = userName
    },
  },
}
